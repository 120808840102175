import React, {useState, useContext, useEffect} from 'react';
import LogoSVG from '../../assets/mint/logo.svg';
import NftSVG from '../../assets/mint/nft.svg';
import TextSVG from '../../assets/mint/text.png';
import ButtonSVG from '../../assets/mint/button.svg';
import Button_OpenSea_SVG from '../../assets/mint/button_opensea.svg';
import './home.css';
import {ConnectContext} from '../../provider/ConnectProvider';
import {errorAlert} from '../../provider/Alert';
import Web3 from 'web3';
import {Oval} from 'react-loading-icons'
import {isMobile} from 'react-device-detect';

const OpenSeaUrl = 'https://opensea.io/collection/astro-chums';
const DiscordUrl = 'https://discord.gg/q3CVhgV5k6';

const Home = () => {
    const {
        walletAddress,
        totalSupply,
        maxSupply,
        contractStatus,
        maxMintAmountPerTx,
        cost,
        networkType,
        isLoading,
        connect,
        disConnect,
        mint,
    } = useContext(ConnectContext);
    const [count, setCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        if (walletAddress) {
            setTotalPrice(Web3.utils.fromWei(String(count * Number(cost))));
        }
    }, [count])

    const handleCountMinus = () => {
        if (count > 0) {
            setCount(count => count - 1);
        }
    }

    const handleCountPlus = () => {
        if (count < maxMintAmountPerTx) {
            setCount(count => count + 1);
        }
    }

    const miniAddress = (address) => {
        const len = address.length;
        const shortAccAddress = address.slice(0, 9) + "..." + address.slice(len - 8, len);
        return shortAccAddress;
    }

    const mintNFT = () => {
        if (count <= 0 || count > maxMintAmountPerTx) {
            errorAlert("Invalid mint amount!");
            return;
        }
        if (totalSupply + count > maxSupply) {
            errorAlert("Max supply exceeded!");
            return;
        }
        if (!walletAddress) {
            errorAlert("Wallet is not connected!");
            return;
        }
        mint(count);
    }

    return (
        <div className='bg-container'>
            <div className='bg-inner-container'>
                <img src={LogoSVG} className="logo"/>
                <div className='alert'>
                    {networkType != 'main' && <>You are not connected to the main network<br/></>}
                    Current network: {networkType}
                </div>
                <div className='wallet-container'>
                    {
                        isLoading && <div className='loading-container'>
                            <Oval stroke="#FFFFFF" width={150} height={150} speed={0.7} strokeWidth={2}/>
                        </div>
                    }
                    <div className='wallet'>
                        <div className='wallet-address'>
                            Wallet Address <br/>
                            <span style={{fontWeight: 'bold', fontSize: '18px'}}>
                {walletAddress && miniAddress(walletAddress)}
              </span>
                        </div>
                        <div className='wallet-inner-container'>
                            <div className='wallet-supply'>
                                Supply <br/>
                                <span style={{fontWeight: 'bold', fontSize: '20px'}}>
                  {totalSupply} / {maxSupply}
                </span>
                            </div>
                            <div className='wallet-status'>
                                Sale Status <br/>
                                <span style={{fontWeight: 'bold', fontSize: '20px'}}>
                  {contractStatus ? 'Paused' : 'Opened'}
                </span>
                            </div>
                        </div>
                    </div>

                    <div className='mint'>
                        <div className='mint-img-container'>
                            <img src={NftSVG}/>
                        </div>
                        <div style={{width: '100%'}}>
                            <div className='total-price'>
                                Total Price <br/>
                                <span style={{fontWeight: 'bold', fontSize: '20px'}}>
                  {totalPrice}
                </span>
                            </div>
                            {
                                walletAddress ?
                                    <div className='count-container'>
                                        <div className='count-minus'
                                             onClick={() => handleCountMinus()}
                                        >-
                                        </div>
                                        <div className='count-value'>
                                            {count}
                                        </div>
                                        <div className='count-plus'
                                             onClick={() => handleCountPlus()}
                                        >+
                                        </div>
                                        <div
                                            className='mint-btn'
                                            style={{
                                                borderBottomRightRadius: 0,
                                                borderRight: '1px solid grey',
                                                width: 70
                                            }}
                                            onClick={() => {
                                                if (!isLoading) {
                                                    mintNFT()
                                                }
                                            }}
                                        >Mint
                                        </div>
                                        <div
                                            className='mint-btn'
                                            onClick={disConnect}
                                        >
                                            Disconnect
                                        </div>
                                    </div> :
                                    <div className='count-container'>
                                        <div className='connect-btn' onClick={connect}>
                                            Connect Wallet
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>


                <div className='text-svg'>
                    <img src={TextSVG}/>
                </div>

                {
                    !isMobile ?
                        (
                            <div className='discord-svg'>
                                <img src={ButtonSVG} onClick={() => {
                                    window.location.href = DiscordUrl
                                }}/>
                                <img src={Button_OpenSea_SVG} onClick={() => {
                                    window.location.href = OpenSeaUrl
                                }}/>
                            </div>
                        )
                        :
                        (
                            <React.Fragment>
                                <div className='discord-svg'>
                                    <img src={ButtonSVG} onClick={() => {
                                        window.location.href = DiscordUrl
                                    }}/>
                                </div>
                                <div className='discord-svg'>
                                    <img src={Button_OpenSea_SVG} onClick={() => {
                                        window.location.href = OpenSeaUrl
                                    }}/>
                                </div>
                            </React.Fragment>
                        )
                }
                {/*<BrowserView>*/}
                {/*    <div className='discord-svg'>*/}
                {/*        <img src={ButtonSVG} onClick={() => {*/}
                {/*            window.location.href = DiscordUrl*/}
                {/*        }}/>*/}
                {/*        <img src={Button_OpenSea_SVG} onClick={() => {*/}
                {/*            window.location.href = OpenSeaUrl*/}
                {/*        }}/>*/}
                {/*    </div>*/}
                {/*</BrowserView>*/}
                {/*<MobileView>*/}
                {/*    <div className='discord-svg'>*/}
                {/*        <img src={ButtonSVG} onClick={() => {*/}
                {/*            window.location.href = DiscordUrl*/}
                {/*        }}/>*/}
                {/*    </div>*/}
                {/*    <div className='discord-svg'>*/}
                {/*        <img src={Button_OpenSea_SVG} onClick={() => {*/}
                {/*            window.location.href = OpenSeaUrl*/}
                {/*        }}/>*/}
                {/*    </div>*/}
                {/*</MobileView>*/}
            </div>
        </div>
    )
}

export default Home;