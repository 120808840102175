import React, { createContext, useState, useEffect } from 'react';
import { successAlert, errorAlert, warningAlert } from './Alert';
import { nftContractAddress, infuraId, netProvider, netNum } from './Constants';
import whitelistAddresses from './whitelist.json';

// Import web3 packages
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'
import ExampleTokenABI from '../abi/ExampleToken.json';

let netId = netNum;
let web3Modal = null;
let provider = null;
let web3 = null;
let providerOptions = {
  opera: {
    package: true
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId,
    }
  }
};
web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
  disableInjectedProvider: false
})


export const ConnectContext = createContext({
  walletAddress: '',
  totalSupply: 0,
  maxSupply: 0,
  contractStatus: true,
  whitelistMintEnabled: false,
  maxMintAmountPerTx: 0,
  cost: 0,
  networkType: '',
  isLoading: false,
  connect: () => { },
  disConnect: () => { },
  mint: (count) => { }
})

const ConnectProvider = ({ children }) => {

  const [providerSrc, setProviderSrc] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [networkType, setNetworkType] = useState('');

  // initial values
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [contractStatus, setContractStatus] = useState(true);
  const [whitelistMintEnabled, setWhitelistMintEnabled] = useState(false);
  const [maxMintAmountPerTx, setMaxMintAmountPerTx] = useState(0);
  const [cost, setCost] = useState(0);

  useEffect(() => {
    cachedConnect();
  }, [])

  useEffect(() => {
    if (providerSrc) {
      (async () => {
        await subscribeProvider(providerSrc);
      })();
    }
  }, [providerSrc])

  useEffect(() => {
    (async () => {
      await initial();
    })();
  }, [])

  const initial = async () => {
    setIsLoading(true);

    let web3Default;
    if (web3) {
      web3Default = web3;
    } else {
      web3Default = new Web3(new Web3.providers.HttpProvider(netProvider));
    }
    const contract = new web3Default.eth.Contract(ExampleTokenABI, nftContractAddress);
    const totalSupply = await contract.methods.totalSupply().call();
    const maxSupply = await contract.methods.maxSupply().call();
    const contractStatus = await contract.methods.paused().call();
    const whitelistMintEnabled = await contract.methods.whitelistMintEnabled().call();
    const maxMintAmountPerTx = await contract.methods.maxMintAmountPerTx().call();
    const cost = await contract.methods.cost().call();
    setTotalSupply(Number(totalSupply));
    setMaxSupply(Number(maxSupply));
    setContractStatus(contractStatus);
    setWhitelistMintEnabled(whitelistMintEnabled);
    setMaxMintAmountPerTx(Number(maxMintAmountPerTx));
    setCost(cost);

    setIsLoading(false);
  }

  const getMerkleTree = () => {
    let leaves = whitelistAddresses.map(addr => keccak256(addr))
    let merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true })
    return merkleTree;
  }
  const getProofForAddress = () => {
    return getMerkleTree().getHexProof(keccak256(walletAddress));
  }

  const mint = async (count) => {
    setIsLoading(true);
    const contract = new web3.eth.Contract(ExampleTokenABI, nftContractAddress);
    const balanceOfWallet = await web3.eth.getBalance(walletAddress);
    const nodeGasPrice = await web3.eth.getGasPrice();

    if (Number(balanceOfWallet) > Number(count * cost)) {
      if (contractStatus) {
        errorAlert("The contract is paused!"); 
        setIsLoading(false);
        return;
        if (whitelistMintEnabled) {
          console.log('here whitelist mint');
          const claimedStatus = await contract.methods.whitelistClaimed(walletAddress).call();
          if (!claimedStatus) {
            await contract.methods.whitelistMint(count, getProofForAddress()).send({
              gasPrice: nodeGasPrice * 2,
              from: walletAddress,
              value: count * cost
            })
              .on('error', function (err) {
                if (err) {
                  let str = err.toString()
                  if (str.includes('Invalid proof')) {
                      errorAlert('You are not on the whitelist!');
                  } else if (str.includes('Address already claimed')) {
                      errorAlert('You have already claimed your whitelist mint!');
                  } else if (str.includes('The whitelist sale is not enabled')) {
                      errorAlert('The whitelist sale is not enabled!');
                  } else {
                      errorAlert(`${err.message.substring(0, 40)} ...`);
                  }
                  setIsLoading(false);
                  return;
                }
              })
              .then(async () => {
                console.log("Success!");
                successAlert(`Success to whitelist mint!`);
                await initial();
                setIsLoading(false);
              })
          } else {
            errorAlert("Address already claimed!");
            setIsLoading(false);
            return;
          }
        } else {
          errorAlert("The whitelist sale is not enabled!");
          setIsLoading(false);
          return;
        }
      } else {
        await contract.methods.mint(count).send({
          gasPrice: nodeGasPrice * 2,
          from: walletAddress,
          value: count * cost
        })
          .on('error', function (error) {
            if (error) {
              errorAlert(`${error.message.substring(0, 40)} ...`);
              setIsLoading(false);
              return;
            }
          })
          .then(async () => {
            console.log("Success!");
            successAlert(`Success to mint!`);
            await initial();
            setIsLoading(false);
          })
      }
    } else {
      errorAlert("Insufficient funds!");
      setIsLoading(false);
      return;
    }
  }

  const connect = async () => {
    if (web3Modal) {
      let hasProvider;
      try {
        provider = await web3Modal.connect();
        hasProvider = true;
      } catch (error) {
        // console.log("provider error: ", error);
        hasProvider = false;
      }
      if (hasProvider) {
        setProviderSrc(provider)
        try {
          await subscribeProvider(provider);
        } catch (error) {
          console.log("subscribe error: ", error);
        }
        web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        setWalletAddress(accounts[0])
        const _networkId = await web3.eth.net.getId();
        const _networkType = await web3.eth.net.getNetworkType();
        setNetworkType(_networkType);

        if (_networkId != netId) {
          warningAlert(`Another network detection, Switch to ${netId == 1 ? 'Ethereum mainnet' : 'Rinkeby testnet'} network!`);
          setWalletAddress(null);
        } else {
          successAlert("Wallet Connected!");
        }
      }
    } else {
      console.log("web3Modal is null");
    }
  }

  const cachedConnect = async () => {
    if (web3Modal.cachedProvider) {
      let hasProvider;
      try {
        provider = await web3Modal.connect();
        hasProvider = true;
      } catch (error) {
        // console.log("provider error: ", error);
        hasProvider = false;
      }
      if (hasProvider) {
        setProviderSrc(provider);
        web3 = new Web3(provider);

        const accounts = await web3.eth.getAccounts();
        setWalletAddress(accounts[0]);

        const _networkId = await web3.eth.net.getId();
        const _networkType = await web3.eth.net.getNetworkType();
        setNetworkType(_networkType);

        if (_networkId != netId) {
          warningAlert(`Another network detection, Switch to ${netId == 1 ? 'Ethereum mainnet' : 'Rinkeby testnet'} network!`);
          setWalletAddress(null);
        }
      }
    }
  }

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    provider.on("accountsChanged", async (accounts) => {
      setWalletAddress(accounts[0]);
      console.log("account changed");
    });
    provider.on("networkChanged", async (networkId) => {
      const _networkType = await web3.eth.net.getNetworkType();
      setNetworkType(_networkType);

      if (networkId != netId) {
        warningAlert(`Another network detection, Switch to ${netId == 1 ? 'Ethereum mainnet' : 'Rinkeby testnet'} network!`);
        setWalletAddress(null);
      }
      console.log("network changed: ", networkId);
    });
  };

  const disConnect = async () => {
    try {
      if (web3Modal) {
        await web3Modal.clearCachedProvider();
      }
      if (provider) {
        provider = null;
        setProviderSrc(provider);
        setWalletAddress(null);
        warningAlert("Wallet Disconnected");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ConnectContext.Provider value={{
      walletAddress,
      totalSupply,
      maxSupply,
      contractStatus,
      whitelistMintEnabled,
      maxMintAmountPerTx,
      cost,
      networkType,
      isLoading,
      connect,
      disConnect,
      mint
    }}>
      {children}
    </ConnectContext.Provider>
  )
}

export default ConnectProvider;