import React from 'react';
import ReactDOM from "react-dom";
import ConnectProvider from './provider/ConnectProvider';
import { ToastContainer } from "react-toastify";
import Home from './pages/home';
import "./index.css";

const Root = () => {
  return (
    <>
      <ToastContainer />
      <ConnectProvider>
        <Home />
      </ConnectProvider>
    </>
  )
}

ReactDOM.render(
  <React.StrictMode> 
    <Root />
  </React.StrictMode>, 
  document.getElementById('root')
);