import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const setting = {
  position: "top-right",
  theme: 'light',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const successAlert = (msg) => {
  return toast.success(msg, setting);
}

export const errorAlert = (msg) => {
  return toast.error(msg, setting);
}

export const warningAlert = (msg) => {
  return toast.warning(msg, setting);
}